/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, useCallback, useLayoutEffect, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import Button, { IconButton } from '@atlaskit/button/new';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import results from '../data/results';
import { type Path, type resultID } from '../data/types';

import TokenItem from './token-item';

const headerStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const leftContainerStyles = css({
	padding: token('space.200', '16px'),
	border: `1px solid ${token('color.border')}`,
	borderRadius: token('border.radius.200', '8px'),
	'@media (min-width: 480px)': {
		height: 492,
		overflow: 'scroll',
	},
});

const resultTitleStyles = css({
	margin: token('space.0', '0px'),
	font: token('font.heading.medium'),
});

const dividerStyles = css({
	height: token('space.200', '16px'),
	border: 'none',
	borderBlockStart: `1px solid ${token('color.border')}`,
});

const COPY_MESSAGE = {
	PROMPT: 'Copy result link',
	SUCCESS: 'Copied',
	FAILURE: 'Copy failed',
};

/**
 * __Result panel__
 *
 * A result panel on the right-hand-side modal dialog to display suggested tokens and descriptions
 *
 */
const ResultPanel = ({
	resultId,
	onClickStartAgain,
	path,
}: {
	resultId: resultID;
	onClickStartAgain: (...args: any) => void;
	path: Path[];
}) => {
	const [copyMessage, setCopyMessage] = useState<string>(COPY_MESSAGE.PROMPT);

	const handleSuccess = useCallback(() => {
		setCopyMessage(COPY_MESSAGE.SUCCESS);
	}, [setCopyMessage]);

	const handleError = useCallback(() => {
		setCopyMessage(COPY_MESSAGE.FAILURE);
	}, [setCopyMessage]);

	const resetPrompt = () => setCopyMessage(COPY_MESSAGE.PROMPT);

	const onCopy = () => {
		try {
			const paramObject: { [key: string]: string } = {
				isTokenPickerOpen: encodeURIComponent(true),
				resultId: encodeURIComponent(resultId),
			};
			path.forEach(({ questionId, answer }) => {
				const encodedQuestionId = encodeURIComponent(questionId);
				paramObject[encodedQuestionId] = encodeURIComponent(answer);
			});
			const link =
				window.location.href.split('?')[0] + '?' + new URLSearchParams(paramObject).toString();
			navigator.clipboard.writeText(link).then(handleSuccess, handleError);
			setTimeout(() => {
				resetPrompt();
			}, 1000);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Unable to copy link');
		}
	};

	const updateTooltip = useRef<() => void>();
	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [copyMessage]);

	return (
		<div css={leftContainerStyles}>
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={[headerStyles, { marginBottom: token('space.400', '32px') }]}>
				{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<div css={[headerStyles, { columnGap: token('space.050', '4px') }]}>
					<Tooltip
						content={({ update }) => {
							updateTooltip.current = update;
							return copyMessage;
						}}
						position="top"
						onHide={resetPrompt}
					>
						{(tooltipProps) => (
							<IconButton
								appearance="subtle"
								icon={LinkIcon}
								label="Copy result link"
								{...tooltipProps}
								onClick={onCopy}
							/>
						)}
					</Tooltip>
					<h5 css={resultTitleStyles}>Your token is:</h5>
				</div>
				<Button appearance="subtle" onClick={onClickStartAgain}>
					Start again
				</Button>
			</div>
			{results[resultId].map((token, index) => {
				return (
					<Fragment key={token.name}>
						<TokenItem
							tokenName={token.name}
							pairings={token.pairings?.find((item) => item.background === token.name)}
						/>
						{index < results[resultId].length - 1 && <hr css={dividerStyles} />}
					</Fragment>
				);
			})}
		</div>
	);
};

export default ResultPanel;
